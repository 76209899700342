export enum InputFields {
  FirstName = "firstName",
  LastName = "lastName",
  BirthDate = "birthDate",
  Position = "position",
  EmployeeNumber = "employeeNumber",
  Group = "group",
  Status = "status"
}

export const placeholders = {
  firstName: "accountManagementPage.placeholders.firstName",
  lastName: "accountManagementPage.placeholders.lastName",
  birthDate: "accountManagementPage.placeholders.birthDate",
  position: "accountManagementPage.placeholders.position",
  employeeNumber: "accountManagementPage.placeholders.employeeNumber",
  tachographNumber:
    "accountManagementPage.placeholders.tachographCardNumber",
  status: "accountManagementPage.placeholders.status"
};

export const copyTextToClipboard = async (text: string) => {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
};

export const validateUserData = (value: string, dataFileld: InputFields) => {
  switch (dataFileld) {
    case InputFields.FirstName:
      return value.length < 25 && value.length >= 2 ? true : false;
    case InputFields.LastName:
      return value.length < 25 && value.length >= 2 ? true : false;
    case InputFields.EmployeeNumber:
      return value.length <= 10 && value.length >= 1 ? true : false;
    default:
      return true;
  }
};
