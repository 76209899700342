import { Box, Button, Switch, styled } from "@mui/material";
import { BasicTableRowStyles } from "../../components/common/Common.styles";
import { theme } from "../../theme";

export const AccountManagementWrapper = styled(Box)(({ theme }) => ({
  height: "auto",
  backgroundColor: theme.palette.backgroundColor.main,
}));

export const AccountManagementEditRowStyles = styled(BasicTableRowStyles)(
  ({ theme }) => ({
    "&:last-child": {
      border: "none",
    },
    "& > div": {
      width: "100%",
      boxSizing: "border-box",
      "&:first-child": {
        paddingLeft: "35px",
        [theme.breakpoints.down(1700)]: {
          "&": {
            padding: "0 0 0 25px!important",
          },
        },
      },
    },
    fieldset: {
      border: "none",
    },
    input: {
      borderRadius: "5px",
    },
  })
);

export const InvitationCodeStyles = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%"
}));

export const AccountManagementBtnsCell = styled(Box)(
  ({
    display: "flex",
    width: "100%",
    paddingRight: "35px!important",
    boxSizing: "border-box",
    justifyContent: "flex-end!important",
    [theme.breakpoints.down(1700)]: {
      "&": {
        padding: "0 25px 0 0!important",
      },
    },
}));

export const SearchBarButtonContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  height: "100%",
  alignItems: "center",
  gap: "1rem",
  justifyContent: "end",
})

export const TableSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&::before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.contrastText),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&::after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));