import { FC } from "react";
import { IconButton, Typography } from "@mui/material";
import CellBox from "../../../components/common/table/CellBox";
import { useAccountManagementEditMode } from "../../../store/selectors/accountManagementSelectors";
import AccountManagementCreateEditUser from "./AccountManagementCreateEditUser";
import BorderRow from "../../../components/common/table/BorderRow";
import { BasicTableRowStyles } from "../../../components/common/Common.styles";
import StatusDisplay from "../../../components/common/table/StatusDisplay";
import { AccountManagementUserType } from "../../../types/accountManagementUsersType";
import { useGroupsData } from "../../../store/selectors/groupSelectors";
import TooltipTypography from "../../../components/common/TooltipTypography";
import { Download } from "@mui/icons-material";
import { InvitationCodeStyles } from "../AccountManagement.styles";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

interface AccountManagementRowProps {
  userData: AccountManagementUserType;
}

const AccountManagementRow: FC<AccountManagementRowProps> = ({ userData }) => {
  const {
    firstName,
    lastName,
    birthDate,
    employeeNumber,
    groupId,
    registrationCode,
    position,
    status,
    onboardingFileDownloadUrl
  } = userData;

  const editMode = useAccountManagementEditMode();

  const groupList = useGroupsData();
  const groupIndex = groupList.map(i => i.id).indexOf(groupId)
  const groupName = groupIndex < 0 ? "" : groupList[groupIndex].name

  if (editMode) return <AccountManagementCreateEditUser user={userData} />;

  return (
    <BasicTableRowStyles>
      <CellBox firstCol={true}>
        <TooltipTypography
          value={firstName}        
        />
      </CellBox>
      <CellBox>
        <TooltipTypography
          value={lastName}
        />
      </CellBox>
      <CellBox>
        <TooltipTypography
          value={birthDate
            ? dayjs(birthDate).format('DD.MM.YYYY')
            : "" }
        />
      </CellBox>
      <CellBox>
        <TooltipTypography
          value={position}
        />
      </CellBox>
      <CellBox>
        <TooltipTypography
          value={employeeNumber}
        />
      </CellBox>
      <CellBox>
        <TooltipTypography
          value={groupName ? groupName : "-"}
        />
      </CellBox>
      <CellBox>
        <StatusDisplay status={status} />
      </CellBox>
      <CellBox>
        <InvitationCodeStyles>
          <Typography variant="caption" fontSize={16}>{registrationCode}</Typography>
          {onboardingFileDownloadUrl && <Link to={onboardingFileDownloadUrl} target="_blank">
            <IconButton>
              <Download />
            </IconButton>
          </Link>}
        </InvitationCodeStyles>
      </CellBox>
      <BorderRow />
    </BasicTableRowStyles>
  );
};

export default AccountManagementRow;
