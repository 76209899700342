import { useEffect, useState } from "react";
import { ActionItem } from "../../types/actionItem";
import { PaginationParameters } from "../../api/Pagination";
import { actionsApi } from "../../api/actionsApi";
import { ActionModuleManagementStyles, SearchBarWrapper } from "./ActionModuleManagement.styles";
import ServerSideSearchBar from "../../components/common/table/ServerSideSearchBar";
import EmployeeIncentivationType from "../../types/employeeIncentivationType";
import IncentivationTable from "./IncentivationTable";
import { Button } from "@mui/material";
import { EditIcon } from "../../assets/icons/Icons";
import SaveIcon from '@mui/icons-material/Save';
import { incentivationUsersApi } from "../../api/incentivationUsersApi";
import { setProcessingRequest } from "../../store/reducers/tableManagementReducer";
import { useAppDispatch } from "../../hooks/StoreHooks";

interface Props {
    actionModule: ActionItem;
}

export default function ActionModuleIncentivation(props: Props) {
    const dispatch = useAppDispatch();
    const [isEditing, setIsEditing] = useState(false);
    const [tableContent, setTableContent] = useState<EmployeeIncentivationType[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [paginationParameters, setPaginationParameters] = useState<PaginationParameters>({
        limit: 10,
        offset: 0
    });

    const [updatedRows, setUpdatedRows] = useState<EmployeeIncentivationType[]>([]);

    useEffect(() => {
        const fetchUsers = async () => {
            setTableContent([]);
            dispatch(setProcessingRequest(true));
            const res = await actionsApi.getEmployeesForActionModule(
                props.actionModule.id,
                paginationParameters
            );
            dispatch(setProcessingRequest(false));
            setTableContent(res?.result?.items ?? []);
            setTotalCount(res?.result?.totalCount ?? 0);
        };
        fetchUsers();
    }, [props.actionModule.id, paginationParameters]);

    function onRowUpdated(newValue: EmployeeIncentivationType) {
        setTableContent((old) => old.map((a) => a.id != newValue.id ? a : newValue));
        setUpdatedRows((old) => old.filter((a) => a.id !== newValue.id).concat([newValue]));
    }

    async function saveUpdatedRows() {
        dispatch(setProcessingRequest(true));
        const results = await Promise.all(
            updatedRows.map((row) =>
                incentivationUsersApi.editUser(row.id!, {
                    fulfilledStatus: row.fulfillmentStatus,
                    scoreValue: row.score,
                    actionModuleId: props.actionModule.id,
                })
            )
        );

        setTableContent((table) => {
            for (const result of results) {
                table = table.map((a) => a.id == result.result?.id
                    ? {
                        ...a,
                        dateOfAction: result.result?.incentiveData?.dateOfAction ?? null,
                        fulfillmentStatus: result.result?.incentiveData?.fulfillmentStatus,
                        points: result.result?.incentiveData?.earnedPoints
                    } as EmployeeIncentivationType : a);
            }

            return table;
        });

        dispatch(setProcessingRequest(false));
        setUpdatedRows([]);
        setIsEditing(false);
    }

    return <ActionModuleManagementStyles>
        <SearchBarWrapper>
            <ServerSideSearchBar onSearchHandler={(s: string) => setPaginationParameters({
                ...paginationParameters,
                search: s,
            })} />
            {
                isEditing
                    ? <Button onClick={saveUpdatedRows}>
                        <SaveIcon />
                    </Button>
                    : <Button onClick={() => setIsEditing(true)}>
                        <EditIcon />
                    </Button>
            }
        </SearchBarWrapper>

        <IncentivationTable
            totalCount={totalCount}
            pagination={paginationParameters}
            onUpdatePagination={setPaginationParameters}
            employees={tableContent}
            actionModule={props.actionModule}
            isEditing={isEditing}
            onRowUpdated={onRowUpdated} />
    </ActionModuleManagementStyles>
}