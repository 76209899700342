import { Container, Typography } from "@mui/material";
import { FC, useState } from "react";
import { ClockIcon } from "../../../../assets/icons/Icons";
import ActionButton from "../../../../components/common/table/ActionButton";
import { CoinsIcon } from "../../../../assets/icons/Icons";
import { RepeatIcon } from "../../../../assets/icons/Icons";
import { usePopover } from "../../../../hooks/UsePopover";
import { ActionItem } from "../../../../types/actionItem";
import { useTranslation } from "react-i18next";
import {
  ActioCardData,
  ActionCardContentWrapper,
  ActionCardHeaderStyles,
  ActionInfoStyles,
  ActionInfoWrapper,
  ActionOptionsStyles,
  ActionsBlockDivider,
  MaxPointsStyles,
} from "../../ActionsBlock.styles";
import UploadActionDocWidget from "./UploadActionDocWidget";
import dayjs from "dayjs";
import ScoreRanges from "./ExcelIncentivation/ScoreRanges";
import FileHandling from "./ExcelIncentivation/FileHandling";

const ActionCard: FC<{
  actionItem: ActionItem;
  searchQuery?: string;
  showScore?: boolean;
  showFileUpload?: boolean;
  onEditClicked?: () => void;
}> = ({ actionItem, showScore, showFileUpload, onEditClicked }) => {
  const {
    name,
    maxPoints,
    rewardedPointsFullyFullfilled,
    iconName,
    colorHex,
    frequency,
    recurring,
    id,
    lastUploadDate,
    scoreRanges,
  } = actionItem;

  const { anchorRef } = usePopover();
  const [uploadPopupIsActive, setUploadPopupIsActive] =
    useState<boolean>(false);

  const { t } = useTranslation();

  return (
    <div ref={anchorRef} id={`${id}_action_card`} onClick={onEditClicked} style={{cursor:'pointer'}}>
      <ActionOptionsStyles>
        
        <ActioCardData style={{ flex: 1 }}>
          <ActionCardHeaderStyles>
            <ActionButton
              iconName={iconName}
              colorHex={colorHex ? colorHex : ""}
              actionName={name}
            />
            <Typography variant="h5">
              {name}
            </Typography>
          </ActionCardHeaderStyles>
          <ActionCardContentWrapper>
            <ActionInfoWrapper>
              <ActionInfoStyles>
                <ClockIcon />
                <Typography variant="body1">
                  {t(`frequency.${frequency.toLowerCase()}`)}
                </Typography>
              </ActionInfoStyles>
              <ActionInfoStyles>
                <CoinsIcon />
                <Typography variant="body1">
                  {maxPoints} {t(`actionModulesPage.actionModules.points`)}
                </Typography>
              </ActionInfoStyles>
              <ActionInfoStyles>
                <RepeatIcon />
                <Typography variant="body1">{t(`actionModulesPage.actionModules.${recurring.toLowerCase()}`)}</Typography>
              </ActionInfoStyles>
              {lastUploadDate != null ?
                <ActionInfoStyles>
                  <Typography variant="body2">
                    {t('actionModulesPage.actionModules.lastUpload')} {dayjs(lastUploadDate!).format("D.MM.YYYY")}
                  </Typography>
                </ActionInfoStyles> :
                <></>
              }
            </ActionInfoWrapper>
            <MaxPointsStyles>
              <Typography variant="h5">
                <span>{rewardedPointsFullyFullfilled}</span>&nbsp;/&nbsp;{maxPoints}
              </Typography>
              <Typography variant="subtitle1">
                <span>{(maxPoints * 100) / 500}%</span>
              </Typography>
            </MaxPointsStyles>
          </ActionCardContentWrapper>
        </ActioCardData>
        {showScore && <>
          <ActionsBlockDivider />
          <ScoreRanges scoreRanges={scoreRanges} />
          <ActionsBlockDivider />
        </>}
        {showFileUpload && <>
          <FileHandling />
        <UploadActionDocWidget
          uploadPopupIsActive={uploadPopupIsActive}
          setUploadPopupIsActive={setUploadPopupIsActive}
          actionModuleId={id}
        />
        </>}
      </ActionOptionsStyles>
    </div>
  );
};

export default ActionCard;
