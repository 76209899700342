import { useRef, useState } from 'react'
import UploadIcon from "@mui/icons-material/Upload";
import { useAppDispatch } from '../../../hooks/StoreHooks';
import { theme } from '../../../theme';
import * as XLSX from "xlsx";
import { setExcelUploadEmployeesParsedData } from '../../../store/reducers/accountManagementReducer';
import { uploadAccountManagementUsersThunk } from '../../../store/thunk/accountManagementThunk';
import { ApplyDocBtn } from '../../actionModules/ActionsBlock.styles';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';

export interface FileUploadData {
    fileContent: string | undefined
}

const FileUploaderBox = () => {
    const dispatch = useAppDispatch();
    const [file, setFile] = useState<File | null>(null);
    const [arrayBuffer, setArrayBuffer] = useState<any>(null);
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();


    const handleClick = () => {
        hiddenFileInput.current && hiddenFileInput.current.click();
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = e.target.files[0];
            if (file.name.match(/^.*\.(xls|xlsx|csv)$/)) {
              setFile(file);
              let reader = new FileReader();
              reader.readAsArrayBuffer(file);
              reader.onload = (e) => {
                setArrayBuffer(e.target!.result);
              };
            }
        }
    };

    const handleOnApplyDoc = () => {
        if (arrayBuffer !== null) {
            const workbook = XLSX.read(arrayBuffer, { type: "buffer", cellText: false, cellDates: true });
            const workbookName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[workbookName];
            const data = XLSX.utils.sheet_to_json(worksheet, { blankrows: false });
            console.log(data);
            dispatch(setExcelUploadEmployeesParsedData(data));
            dispatch(uploadAccountManagementUsersThunk());
            setFile(null);
        }
      };

    return (
        <div>
            { file ? (
                    <ApplyDocBtn sx={{ width: "100%", height: "100%", fontSize: "60%" }} onClick={handleOnApplyDoc}>
                        { t("buttons.upload") }
                    </ApplyDocBtn>) : (
                <IconButton onClick={() => handleClick()}>
                    <input type="file" ref={hiddenFileInput} style={{ display: "none" }} onChange={handleChange} />
                    <UploadIcon sx={{ color: file ? theme.palette.border.subform : theme.palette.companyMain.main }} />
                </IconButton>
            )}
        </div>
    )
}

export default FileUploaderBox