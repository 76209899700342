import { Box, Typography } from "@mui/material";
import CellBox from "../../../components/common/table/CellBox";
import { ChangeEvent, FC, FocusEvent, useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  InputFields,
  placeholders,
  validateUserData,
} from "../utils";
import {
  useAccountManagementEditMode,
  useAccountManagementNewUserMode,
  useAccountManagementUsers,
} from "../../../store/selectors/accountManagementSelectors";
import { useAppDispatch } from "../../../hooks/StoreHooks";
import {
  createNewAccountManagementUserThunk,
  deleteAccountManagementUserThunk,
} from "../../../store/thunk/accountManagementThunk";
import DeleteBtn from "../../../components/common/table/DeleteBtn";
import {
  setAccountManagementUsersToEdit,
  setNewUserMode,
} from "../../../store/reducers/accountManagementReducer";
import InputCellBox from "./InputCellBox";
import { useTranslation } from "react-i18next";
import BorderRow from "../../../components/common/table/BorderRow";
import SaveBtn from "../../../components/common/table/SaveBtn";
import { EmployeeGeneral } from "../../../types/employeeType";
import {
  AccountManagementBtnsCell,
  AccountManagementEditRowStyles,
} from "../AccountManagement.styles";
import { StatusTypes } from "../../../types/common";
import { AccountManagementUserType } from "../../../types/accountManagementUsersType";
import DropDownGroups from "./DropDownGroups";
import { theme } from "../../../theme";
import { useGroupsData } from "../../../store/selectors/groupSelectors";
import { setProcessingRequest, setResponseMessage, setResponseValue } from "../../../store/reducers/tableManagementReducer";
import { ModalComponent } from "../../../components/common/ModalComponent";
import TimePickerUIWrap from "../../../components/common/TimePickerUIWrap";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

interface AccountManagementCreateEditUserProps {
  user?: AccountManagementUserType;
}

type ErrorsType = {
  firstName: string;
  lastName: string;
  employeeNumber: string;
};

const AccountManagementCreateEditUser: FC<
  AccountManagementCreateEditUserProps
> = ({ user }) => {
  const { t } = useTranslation();
  const [userData, setUserData] = useState<Partial<AccountManagementUserType>>({
    id: "",
    firstName: "",
    lastName: "",
    birthDate: undefined,
    position: "",
    status: "",
    registrationCode: "",
    employeeNumber: "",
    groupId: undefined,
    group: undefined
  });
  const newUserMode = useAccountManagementNewUserMode();
  const editMode = useAccountManagementEditMode();
  const tableData = useAccountManagementUsers();
  const dispatch = useAppDispatch();

  const [showModal, setShowModal] = useState<boolean>(false)
  const [modalMessage, setModalMessage] = useState<string>("")
  const closeModal = () => {
    setShowModal(false)
  }

  const [errors, setErrors] = useState<ErrorsType>({
    firstName: "",
    lastName: "",
    employeeNumber: "",
  });

  const groupList = useGroupsData();

  useEffect(() => {
    user && setUserData(user);
  }, [user]);

  const { registrationCode } = userData;

  const handleDropDownGroupId = (selectedGroupId: number) => {
    setUserData((state) => {
      const newState = { ...state };
      newState["groupId"] = Number(selectedGroupId);
      return newState;
    });

    const groupIndex = groupList.map(i => i.id).indexOf(Number(selectedGroupId))
    const groupName = groupIndex < 0 ? "" : groupList[groupIndex].name
    dispatch(setAccountManagementUsersToEdit({ ...userData as AccountManagementUserType, groupId: Number(selectedGroupId), groupName }));
  }

  const handleOnChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    inputName: InputFields
  ) => {
    if (inputName !== "status") {
      setUserData((state) => {
        const newState = { ...state };
        if (inputName !== "group") {
          newState[inputName] = e.target.value;
        }
        return newState;
      });
    } else {
      let newStatus: string = ""
      let event = e as ChangeEvent<HTMLInputElement>
      newStatus = event.target.checked ? StatusTypes.Active : StatusTypes.Blocked
      setUserData((state) => {
        const newState = { ...state };
        newState["status"] = newStatus;
        return newState;
      });
      let dispatchData = userData as AccountManagementUserType
      dispatchData = { ...dispatchData, status: newStatus }
      dispatch(setAccountManagementUsersToEdit(dispatchData));
    }
  };

  const handleOnBlur = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: Partial<InputFields>
  ) => {
    const value = e.target.value;
    const isValid = validateUserData(value, fieldName);
    if (isValid) {
      editMode &&
        dispatch(setAccountManagementUsersToEdit(userData as AccountManagementUserType));
      setErrors((state) => {
        const newState = { ...state };
        newState[fieldName as keyof ErrorsType] = "";
        return newState;
      });
    }
    if (!isValid) {
      setErrors((state) => {
        const newState = { ...state };
        newState[fieldName as keyof ErrorsType] = t(
          "accountManagementPage.errors.notValid"
        );
        return newState;
      });
    }
  };

  const handleOnSave = async () => {
    let saveError = false
    for (let key in userData) {
      if (
        (key === InputFields.FirstName ||
          key === InputFields.LastName ||
          key === InputFields.BirthDate ||
          key === InputFields.Position ||
          key === InputFields.EmployeeNumber) &&
        !userData[key as keyof EmployeeGeneral]
      ) {
        saveError = true
        setModalMessage(t("dialogModals.fieldNotFilled"))
        setShowModal(true)
        return;
      }
    }
    tableData.forEach(x => {
      if (x.employeeNumber === userData.employeeNumber) {
        saveError = true
        setModalMessage(t("dialogModals.employeeNumberDuplication"))
        setShowModal(true)
        return
      }
    })
    for (let key in errors) {
      if (errors[key as keyof ErrorsType])
        return;
    }

    if (!saveError) {
      dispatch(setProcessingRequest(true))
      dispatch(setNewUserMode(false))
      let res = await dispatch(createNewAccountManagementUserThunk(userData as AccountManagementUserType));
      dispatch(setResponseValue(res.type))
      dispatch(setResponseMessage(t("dialogModals.itemCreatedUser")))
    }
  };

  const handleOnDelete = async () => {
    if (!user) {
      dispatch(setNewUserMode(false));
      return;
    } else {
      dispatch(setProcessingRequest(true))
      let res = await dispatch(deleteAccountManagementUserThunk(user.id))
      dispatch(setResponseValue(res.type))
      dispatch(setResponseMessage(t("dialogModals.itemDeletedUser")))
    }
  };

  const handleOnBirthDateChange = (newBirthDate: dayjs.Dayjs | null) => {
    let userBirthDate = newBirthDate?.format('YYYY-MM-DDTHH:mm:ss')
    userBirthDate && setUserData({ ...userData, birthDate: userBirthDate })
    userBirthDate && dispatch(setAccountManagementUsersToEdit({ ...userData as AccountManagementUserType, birthDate: userBirthDate }))
};

  const inputFields = Object.values(InputFields).map((fieldName) => {
    const value = userData[fieldName];
    switch(fieldName) {
      case InputFields.Group:
        return <CellBox
          sx={{
            paddingLeft: "35px",
            [theme.breakpoints.down(1700)]: {
              "&": {
                padding: "0 0 0 25px!important",
              },
            },
          }}
        >
        <DropDownGroups
          currentSelectionId={userData.groupId ? userData.groupId : 0}
          handleUpdate={handleDropDownGroupId}
          zeroValueAllowed
        />
      </CellBox>;
      case InputFields.BirthDate:
        return <CellBox>
        <TimePickerUIWrap
            sx={{ maxWidth: "150px", marginLeft: "5px", marginBottom: "0" }}
        >
            <DatePicker
                value={dayjs(`${userData.birthDate}`)}
                onChange={handleOnBirthDateChange}
                format="DD.MM.YYYY"
                slots={{ openPickerIcon: ExpandMoreIcon }}
            />
        </TimePickerUIWrap>
    </CellBox>
      default:
        return <InputCellBox
          value={value as string}
          key={fieldName}
          isDisabled={false}
          handleOnBlur={handleOnBlur}
          handleOnChange={handleOnChange}
          inputField={fieldName}
          isWarning={value === ""}
          error={errors[fieldName as keyof ErrorsType]}
          placeholder={t(placeholders[fieldName])}
        />;
      }
  });

  return (
    <AccountManagementEditRowStyles>
      {inputFields}
      <CellBox>
          <Typography variant="caption" fontSize={16}>{registrationCode}</Typography>
      </CellBox>
      <AccountManagementBtnsCell>
        {newUserMode && (
          <SaveBtn
            handleOnClick={handleOnSave}
            hoverText={t("label.saveUser")}
          />
        )}
        <DeleteBtn
          isCreateNewMode={newUserMode}
          dialogMessage={t("dialogModals.confirmDeleteUser")}
          handleOnDelete={handleOnDelete}
        />
      </AccountManagementBtnsCell>
      <BorderRow />

      <ModalComponent isOpen={showModal} onClose={closeModal}>
        <Box>{modalMessage}</Box>
      </ModalComponent>
    </AccountManagementEditRowStyles>
  );
};

export default AccountManagementCreateEditUser;
